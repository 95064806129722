import { useRevenueSummaryStore } from '~/packages/revenue/store/revenue-summary.store';
import { RevenueStatusAPI } from '~/packages/revenue/api/revenue-summary.api';

export default defineNuxtRouteMiddleware(async () => {
  const store = useRevenueSummaryStore();

  if (store.currentQuarter) {
    return;
  }

  try {
    const summary = await RevenueStatusAPI.fetchStatus();
    store.setCurrentQuarter(summary);
  } catch (e) {
    throw e;
  }
});

import { AppService } from '~/app/app.service';
import { RevenueSummary } from '../types/revenue-summary.types';
import { Faker } from '~/packages/fake-data/faker';
import { RevenueSummaryFakeData } from '../fake-data/revenue-summary.fake-data';

export class RevenueStatusAPI extends AppService {
  static fetchStatus(): Promise<RevenueSummary> {
    if (Faker.isEnabled()) {
      return RevenueSummaryFakeData.fetchSummaryFull();
    }

    return RevenueSummaryFakeData.fetchSummaryEmpty();

    // return RevenueStatusAPI.api.core
    //   .get('/analytics/revenue/summary')
    //   .then((response: any) => response.data.payload.data);
  }
}

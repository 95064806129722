import { RevenueSummary } from '../types/revenue-summary.types';

export class RevenueSummaryFakeData {
  static fetchSummaryEmpty(): Promise<RevenueSummary> {
    return Promise.resolve({
      opportunities: {
        created: false,
        createDate: null,
      },
      targetQuarter: null,
    });
  }

  static fetchSummaryFull(): Promise<RevenueSummary> {
    return Promise.resolve({
      opportunities: {
        created: true,
        createDate: new Date().toISOString(),
      },
      targetQuarter: 25_000_000,
    });
  }
}
